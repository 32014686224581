import { Select } from "@chakra-ui/react";
import { useDateFormatter } from "@react-aria/i18n";

function YearSelector(props) {
  const { state } = props;
  let years = [];
  let formatter = useDateFormatter({
    year: "numeric",
    timeZone: state.timeZone,
  });

  // Format 20 years on each side of the current year according
  // to the current locale and calendar system.
  for (let i = -20; i <= 20; i++) {
    let date = state.focusedDate.add({ years: i });
    if (state.minValue && date.compare(state.minValue) <= 0) {
      continue;
    }
    if (state.maxValue && date.compare(state.maxValue) >= 0) {
      continue;
    }
    years.push({
      value: date,
      formatted: formatter.format(date.toDate(state.timeZone)),
    });
  }

  let onChange = (e) => {
    let index = Number(e.target.value);
    let date = years[index].value;
    state.setFocusedDate(date);
  };

  return (
    <Select
      size="sm"
      aria-label="Year"
      onChange={onChange}
      value={years.findIndex(
        (year) => 0 === year.value.compare(state.focusedDate),
      )}
      w={90}>
      {years.map((year, i) => (
        // use the index as the value so we can retrieve the full
        // date object from the list in onChange. We cannot only
        // store the year number, because in some calendars, such
        // as the Japanese, the era may also change.
        <option key={i} value={i}>
          {year.formatted}
        </option>
      ))}
    </Select>
  );
}

export default YearSelector;
