// deps
import { useRef } from "react";
import { useCalendarState } from "@react-stately/calendar";
import { useCalendar } from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import { Box, HStack, Icon } from "@chakra-ui/react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

// components
import CalendarGrid from "./CalendarGrid";
import createCalendar from "./createCalendar";
import MonthSelector from "./MonthSelector";
import YearSelector from "./YearSelector";
import CalendarIconButton from "./CalendarIconButton";

function Calendar(props) {
  let { locale } = useLocale();
  let state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  let ref = useRef();
  let { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    state,
    ref,
  );

  return (
    <div {...calendarProps} ref={ref}>
      <Box display="flex" alignItems="center" paddingBottom="4">
        <CalendarIconButton
          {...prevButtonProps}
          icon={<Icon as={MdChevronLeft} w={6} h={6} />}
        />

        <HStack spacing=".5rem" px=".5rem">
          <MonthSelector state={state} />

          <YearSelector state={state} />
        </HStack>

        <CalendarIconButton
          {...nextButtonProps}
          icon={<Icon as={MdChevronRight} w={6} h={6} />}
        />
      </Box>

      <CalendarGrid state={state} />
    </div>
  );
}

export default Calendar;
